<script setup>
import { onClickOutside } from '@vueuse/core'
import useLobIcons from '@/utils/lobIcons'

const authStore = useAuth()
const user = computed(() => authStore.user)
const name = ref('')
const initialName = ref('')
const lobIcons = useLobIcons()
const mappedLob = await authStore.getMappedLob(lobIcons)
const userLob = reactive(mappedLob)
const isMultiLob = authStore.isMultiLob

if (authStore.activeLob)
  authStore.setSelectedLob(authStore.activeLob, userLob)

onMounted(() => {
  name.value = `${user.value.first_name} ${user.value.last_name}`
  initialName.value = user.value.first_name?.substring(0, 1) + user.value.last_name?.substring(0, 1)
})

async function logout() {
  await authStore.logout()

  return navigateTo('/login')
}

const showDropdown = ref(false)
const showLobList = ref(false)

const lobList = computed(() => {
  return userLob.filter(lob => lob.is_selected === false)
})

const activeLob = computed(() => userLob.find(lob => lob.is_selected))

function handleSwitchLob(id) {
  showDropdown.value = false
  userLob.map(lob => lob.is_selected = lob.lob_id === id)
  authStore.setActiveLob(id)
  return navigateTo('/')
}

const dropdownRef = ref(null)
onClickOutside(dropdownRef, e => showDropdown.value = e.target.dropdown ?? false)
</script>

<template>
  <nav class="flex items-center bg-white py-5 justify-end flex-wrap w-full">
    <div class="flex items-center justify-end w-auto mr-8 shadow-kp">
      <div class="text-sm flex items-center justify-center">
        <a
          href="#"
          class="text-lg text-blue-600 hover:text-blue-400 mr-4 bg-[#ECF6FF] w-8 h-8 flex items-center justify-center rounded-lg"
          title="Notifikasi"
        >
          <Icon name="mdi:bell-outline" class="text-2xl" />
        </a>
        <a
          href="#"
          class=" text-lg text-blue-600 hover:text-blue-400 mr-4 bg-[#ECF6FF] w-8 h-8 flex items-center justify-center rounded-lg"
          title="Sinkronisasi"
        >
          <Icon name="mdi:sync" class="text-2xl" />
        </a>
        <button
          class="text-[14px] font-[600] text-white bg-blue-600 w-9 h-9 rounded-full uppercase flex items-center justify-center hover:shadow-lg"
          .dropdown="showDropdown"
          @click.stop="showDropdown = !showDropdown"
        >
          {{ initialName }}
        </button>
      </div>
    </div>

    <!-- Start Dropdown Profile -->
    <div
      v-if="showDropdown"
      ref="dropdownRef"
      class="absolute text-sm w-[286px] flex flex-col divide-y top-16 right-8 bg-white shadow-md rounded-lg overflow-hidden z-50"
    >
      <div class="flex items-center justify-between gap-2 px-6 py-4">
        <div class="w-[170px] font-normal capitalize">
          Hai, {{ name.toLowerCase() }}
        </div>
        <div class="flex items-center gap-1 bg-[#ECF6FF] text-blue-600 text-xs font-medium p-1 rounded">
          <component :is="activeLob.lob_icon" size="12" />
          <span>{{ activeLob.lob_name }}</span>
        </div>
      </div>
      <div v-if="isMultiLob" class="flex items-center justify-between gap-2">
        <BaseAccordion
          :expanded="showLobList"
          :borderless="true"
          title="Ganti Lini Bisnis"
          class="w-full"
          size="no-spacing"
          title-class="py-4 text-[#2A2A2A] px-6 hover:bg-[#ECF6FF] cursor-pointer"
          @toggle="showLobList = !showLobList"
        >
          <div class="flex flex-col">
            <li
              v-for="item in lobList" :key="item.lob_id"
              class="flex items-center justify-between pl-10 pr-6 py-[10px] hover:bg-[#ECF6FF] cursor-pointer"
              @click="handleSwitchLob(item.lob_id)"
            >
              <div class="flex items-center gap-3">
                <div class="p-2 rounded-lg border border-[#DCDBDB]">
                  <component :is="item.lob_icon" custom-class="size-4" />
                </div>
                <span>{{ item.lob_name }}</span>
              </div>
              <Icon name="ic:keyboard-arrow-right" size="20" class="text-blue-600" />
            </li>
          </div>
        </BaseAccordion>
      </div>
      <div class="flex items-center gap-2 px-6 py-4">
        <div class="text-[#B22A09] cursor-pointer" @click="logout">
          Keluar
        </div>
      </div>
    </div>
    <!-- End Dropdown Profile -->
  </nav>
</template>

<style scoped>
.shadow-kp {
  box-shadow: 0px 2px 4px 0px #60617029;

  box-shadow: 0px 0px 1px 0px #28293d0a;
}
</style>
